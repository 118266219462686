import { useState, useEffect, useRef } from "react";
import "./Gallery.css";
import foto1 from "../assets/Home1.jpeg";
import foto2 from "../assets/Home2.jpeg";
import foto4 from "../assets/Home4.jpeg";

const Fotos = [
    {
        url: "/Home3.jpeg",
    },
    {
        url: foto4,
    },
    {
        url: foto1,
    },
    {
        url: foto2,
    },
];

export default function Gallery() {
    const [index, setIndex] = useState(0);
    const timerRef = useRef(null);

    function handleNextClick() {
        let indexListLength = Fotos.length - 1;
        if (index < indexListLength) {
            setIndex(index + 1);
        } else {
            setIndex(0);
        }
    }

    useEffect(() => {
        if (timerRef.current) {
            clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
            handleNextClick();
        }, 3000);
    });

    let fotos = Fotos[index];

    return (
        <div className="gallery">
            <img src={fotos.url} alt="Imagen de presentacion" />
        </div>
    );
}
