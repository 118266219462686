import React from "react";
import { Cliente } from "./cliente";
export const Conjunto = () => {
    return (
        <>
            <Cliente url={"/Bingo.jpg"} title={"Grupo midas"} />
            <Cliente url={"/Tetra.jpg"} title={"Tetra"} />
            <Cliente url={"/Rapanui.jpg"} title={"Rapanui"} />
            <Cliente url={"/FAIP.jpg"} title={"FAIP"} />
            <Cliente url={"/INTI.png"} title={"INTI"} />
            <Cliente url={"/Kromberg.jpg"} title={"Kromberg"} />
            <Cliente url={"/Gia.jpg"} title={"Gia"} />
            <Cliente url={"/Dosen.jpg"} title={"Dosen"} />
            <Cliente url={"/coto.jpg"} title={"Coto"} />
        </>
    );
};
