import React from "react";

export const Producto = ({ title, img }) => {
    return (
        <div className="relative product__slide w-[85vw] max-w-[175px] sm:max-w-[250px]  rounded-md duration-300 group">
            <div className="w-[85vw] h-[85vw] max-w-[175px] max-h-[175px] sm:max-w-[250px] sm:max-h-[250px] grid place-content-center rounded-md imgCont">
                <img
                    loading="lazy"
                    src={img}
                    alt={title}
                    title={title}
                    className={` bg-white object-contain object-center rounded-md  w-[85vw] h-[85vw] max-w-[175px] max-h-[175px] sm:max-w-[250px] sm:max-h-[250px]`}
                />
            </div>
            <h4 className="absolute right-0 left-0  mt-2 whitespace-nowrap m-auto text-base text-gray-100 text-center grid place-content-center">
                {title}
            </h4>
        </div>
    );
};
