import React from "react";
import { Conjunto } from "./conjunto";

export const Clientes = () => {
    return (
        <section className=" w-full flex flex-col justify-center items-center pt-14">
            <h3 className="font-bold text-3xl rounded mb-2.5 lg:text-4xl mt-5 md:mt-0 text-white bg-[#29335cfa] p-4 text-center">
                Clientes que nos eligen
            </h3>
            <div className="w-full overflow-hidden">
                <div className="slide__track flex overflow-hidden items-center  md:flex-row gap-x-14 pb-12 justify-center">
                    <Conjunto />
                    <Conjunto />
                </div>
            </div>
        </section>
    );
};
