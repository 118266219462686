export function Avatar1({titulo, texto }) {
    return (
        <div className="w-full flex justify-center items-center flex-col py-3 text-white">
            <div className="flex flex-col justify-center items-center">
                <h3 className="font-semibold text-lg">{titulo}</h3>
                <p className="">{texto}</p>
            </div>
        </div>
    );
}

export function Avatar2({ icon, titulo, texto }) {
    return (
        <div className="flex flex-col sm:flex-row justify-center items-center py-3 text-white">
            <div className="">
                <span>{icon}</span>
            </div>
            <div className="mt-2 sm:mt-0 sm:ml-3">
                <h3>{titulo}</h3>
            </div>
        </div>
    );
}
