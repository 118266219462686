import { FaPlane, FaShop } from "react-icons/fa6";
import { Avatar1, Avatar2 } from "./Avatar";

export function Data1() {
    return (
        <section className="bg-[#29335C] ">
            <div className="py-3 md:flex justify-evenly items-center">
                <Avatar1 titulo={"+50"} texto={"Años de experiencia"} />
                <Avatar1 titulo={"+200"} texto={"Variedad de productos"} />
                <Avatar1 titulo={"+5"} texto={"Marcas internacionales"} />
                <Avatar1 titulo={"+20"} texto={"Marcas"} />
            </div>
        </section>
    );
}

export function Data2() {
    return (
        <section className="bg-[#29335C] py-3 sm:flex justify-evenly items-center -translate-y-2">
            <Avatar2
                titulo={"Envios a Todo el Pais"}
                icon={<FaPlane className="text-3xl" />}
            />
            <Avatar2
                titulo={"Local a la callle"}
                icon={<FaShop className="text-3xl" />}
            />
        </section>
    );
}
