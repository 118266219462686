import React from "react";
import "./productos.css";

import { Conjunto } from "./conjunto";

export default function Productos() {
    return (
        <section className=" bg-[#29335C] pt-20 pb-10 -translate-y-2">
            <h2 className="font-bold text-center sm:text-left text-5xl md:mr-auto sm:text-6xl  text-white sm:px-[5%]">
                Nuestros productos
            </h2>
            <div className="w-full overflow-hidden">
                <div className="slide__track flex overflow-hidden items-center mt-10 md:flex-row gap-x-14 pt-6 pb-12 sm:pt-10 justify-center">
                    <Conjunto />
                    <Conjunto />
                </div>
            </div>
        </section>
    );
}
