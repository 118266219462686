import { useState } from "react";

function Navbar() {
    const [menuShow, setMenuShow] = useState(false);
    return (
        <header className=" shadow-md md:rounded w-full bg-[#29335cfa] p-2 fixed z-50 flex justify-between items-center h-[10vh] md:h-[8vh] md:w-[90%] md:right-0 md:left-0 md:mx-auto md:mt-2">
            <img
                src={"/logoNav.png"}
                alt="Logo CP Goma "
                className="h-[6vh] invert"
            />
            <div
                onClick={() => setMenuShow(!menuShow)}
                className={`z-20 flex h-12 w-14 flex-col items-center justify-center rounded md:hidden`}
            >
                <div
                    className={`h-0.5 w-8 rounded bg-white duration-300 ${
                        menuShow && "translate-y-0.5 rotate-45"
                    }`}
                ></div>
                <div
                    className={`my-1.5 h-0.5 w-8 rounded bg-white ${
                        menuShow && "hidden"
                    }`}
                ></div>
                <div
                    className={`h-0.5 w-8 rounded bg-white duration-300 ${
                        menuShow && "-rotate-45"
                    }`}
                ></div>
            </div>
            <nav
                className={` translate-x-0 ${
                    !menuShow && " translate-x-full"
                } md:translate-x-0 duration-300 absolute w-[70%] max-w-[300px] h-screen top-0 right-0 bg-[#29335C] md:bg-transparent md:h-[8vh] md:max-w-auto md:w-full`}
            >
                <ul className="w-full h-full flex justify-center items-center flex-col text-white md:flex-row">
                    <li>
                        <a href="#inicio" className="hover:text-gray-300">
                            Inicio
                        </a>
                    </li>
                    <li className="my-5 md:my-0 md:mx-5">
                        <a
                            href="https://cpgoma.mercadoshops.com.ar/"
                            target="_blank"
                            rel="noreferrer"
                            className="hover:text-gray-300"
                        >
                            Tienda
                        </a>
                    </li>
                    <li className="mb-5 md:mb-0 md:mr-5">
                        <a href="#nosotros" className="hover:text-gray-300">
                            Nosotros
                        </a>
                    </li>
                    <li>
                        <a href="#contacto" className="hover:text-gray-300">
                            Contacto
                        </a>
                    </li>
                </ul>
            </nav>
        </header>
    );
}

export default Navbar;
